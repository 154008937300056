import { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import 'react-phone-input-2/lib/material.css';

function PhoneNumberControl(props) {
  const {
    disabled,
    events = {},
    error,
    label,
    name,
    onChange,
    placeholder,
    settings = {},
    value,
  } = props;

  const handleChange = function (phone) {
    const { prefix = '+' } = settings;
    const target = {
      name,
      value: `${prefix}${phone}`,
    };
    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }
  };

  useEffect(() => {
    const el = document.querySelector('.special-label');
    if (el) {
      el.style.left = '8px';
      el.style.color = disabled ? 'gray' : undefined;
    }
  }, [disabled]);

  return (<>
    <PhoneInput
      country={settings?.defaultCountry || 'mx'}
      disabled={disabled}
      disableDropdown={settings?.disableDropdown}
      inputProps={{
        style: {
          cursor: disabled ? 'default' : undefined,
          width: '100%',
        },
      }}
      isValid={() => !!!error}
      onChange={handleChange}
      placeholder={placeholder}
      prefix={settings?.prefix || '+'}
      specialLabel={label}
      value={value}
      countryCodeEditable={settings.countryCodeEditable}
    />
    {props.helperText && <Typography
      color='error'
      children={props.helperText}
      variant="caption"
      style={{
        marginLeft: '14px',
      }}
    />}
  </>);
}

PhoneNumberControl.defaultProps = {
  disabled: false,
  error: false,
  onChange: () => { },
  onBlur: () => { },
};

PhoneNumberControl.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  settings: {
    defaultCountry: PropTypes.string,
    countryCodeEditable: PropTypes.bool,
    disableDropdown: PropTypes.bool,
    onlyCountries: PropTypes.arrayOf(PropTypes.string),
    prefix: PropTypes.string,
  },
  value: PropTypes.string,
};

export default PhoneNumberControl;
