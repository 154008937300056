import { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

function Fieldset({ label, children }) {
  return (
    <fieldset
      style={{
        borderRadius: 5,
      }}
    >
      <legend style={{
        marginLeft: 8,
      }}>
        <Typography color="textSecondary" variant="caption">
          <Box
            children={label}
            style={{
              marginLeft: 5,
              marginRight: 5,
            }}
          />
        </Typography>
      </legend>
      <Box
        children={children}
        style={{
          margin: 12,
        }}
      />
    </fieldset>);
}

function SplittedText(props) {
  const {
    disabled,
    events,
    error,
    label,
    name,
    onChange,
    settings,
    size,
    value,
  } = props;
  const { length = 6 } = settings;
  const rndId = Math.floor(Math.random() * 1000000);
  const Container = label ? Fieldset : Fragment;

  const handleChange = function (code, index) {
    const splittedValue = (value || '').split('');
    splittedValue[index] = code;

    const target = {
      name,
      value: splittedValue.join(''),
    };

    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }

    selectNextInput(index);
  };

  const handleKeyDown = function (event, index) {
    const KEYS = {
      arrowLeft: {
        code: 37,
        name: 'ArrowLeft',
      },
      arrowRight: {
        code: 39,
        name: 'ArrowRight',
      },
      backspace: {
        code: 8,
        name: 'Backspace',
      },
    };

    if (event.keyCode === KEYS.backspace.code || event.key === KEYS.backspace.name) {
      event.preventDefault();

      const target = {
        name,
        value: (value || '').substring(0, index),
      };

      onChange({
        target,
      });

      if (events?.onChange) {
        events.onChange(target);
      }

      selectBackInput(index);
    } else if (event.keyCode === KEYS.arrowLeft.code || event.key === 'ArrowLeft') {
      event.preventDefault();

      selectBackInput(index);
    } else if (event.keyCode === KEYS.arrowRight.code || event.key === 'ArrowRight') {
      event.preventDefault();

      selectNextInput(index);
    }
  };

  const handlePaste = function (event, index) {
    event.preventDefault();

    const text = event.clipboardData.getData('text/plain');
    const splittedValue = text
      .substring(0, length - index)
      .split('');

    const target = {
      name,
      value: (value || '').substring(0, index) + splittedValue.join(''),
    };

    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }

    selectNextInput(target.value.length - (target.value.length === length ? 2 : 1));
  };

  const getValue = function (index) {
    const splittedValue = (value || '').split('');

    return splittedValue[index] || '';
  };

  const selectBackInput = function (index) {
    if (index > 0) {
      const elemId = document.getElementById(`${name}__${rndId}_${index - 1}`);

      elemId.focus();
      elemId.select();
    }
  };

  const selectNextInput = function (index) {
    if (index + 1 < length) {
      const elemId = document.getElementById(`${name}__${rndId}_${index + 1}`);

      elemId.focus();
      elemId.select();
    }
  };

  return (<Container label={label}>

    {(function () {
      const Components = [];

      for (let index = 0; index < length; index++) {
        Components.push(<Grid
          item
          key={index}
          md={12 / length}
          sm={12 / length}
          xs={12 / length}
        >
          <TextField
            autoComplete="off"
            autoFocus={false}
            disabled={disabled}
            fullWidth
            inputProps={{
              id: `${name}__${rndId}_${index}`,
              maxLength: 1,
              style: {
                textAlign: 'center',
              },
            }}
            onChange={({ target }) => handleChange(target.value, index)}
            onKeyDown={(event) => handleKeyDown(event, index)}
            onPaste={(event) => handlePaste(event, index)}
            size={size}
            variant="outlined"
            value={getValue(index)}
          />
        </Grid>);
      }

      return (<Grid
        children={Components}
        container
        spacing={1}
      />);
    }())}
    {error && (<Typography
      children={error}
      color="error"
      variant="caption"
    />)}
  </Container>);
}

Fieldset.propTypes = {
  label: PropTypes.string,
  children: PropTypes.element,
};

SplittedText.defaultProps = {
  disabled: false,
  onChange: () => { },
  settings: {},
  size: 'small',
};

SplittedText.propTypes = {
  disabled: PropTypes.bool,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  settings: PropTypes.shape({
    length: PropTypes.number,
  }),
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  value: PropTypes.string,
};

export default SplittedText;
